import React from "react";

const EventDialog = ({events, onClear}) => {
    if (events === null || events.length === 0) {
        return <div className="ticket-event-dialog hidden"></div>
    }

    const timelineEvents = events.map((event, index) =>
        <div className="ticket-event-dialog-content-row" key={index}>
            {event.message}
        </div>
    );


    return (
        <div className="ticket-event-dialog show">
            <div className="ticket-event-dialog-header">
                New Update
                <a className="ticket-event-dialog-header-close" onClick={onClear}>X</a>
            </div>
            <div className="ticket-event-dialog-content">
                {timelineEvents}
            </div>
        </div>
    );
}

export default EventDialog;