import React from 'react'
import PropTypes from "prop-types";
import {HelpIcon} from "../widgets/HelpIcon";
import {buildModal, useModal} from "../modals/Modal";

const LabeledField = ({name, label, type = "text", value, onChange, helpText}) => {
    const {setModal} = useModal();

    const onHelpClick = () => {
        const modalBody = (
            <div>
                {helpText}
            </div>
        )

        const modal = buildModal(label, modalBody)
        setModal(modal);
    }

    return (
        <div className="pb-field-group">
            <div>
                <label>{label}</label>
                {helpText && <HelpIcon onClick={() => onHelpClick()}></HelpIcon>}
            </div>

            <input className="pb-field"
                   type={type}
                   value={value}
                   onChange={(e) => onChange(name, e.target.value)}
                   defaultChecked={type === "checkbox" && value}
            ></input>
        </div>
    );
}

LabeledField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
};

export default LabeledField;