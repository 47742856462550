import React, {useState} from 'react'
import PropTypes from "prop-types";
import PbButton from "../../common/buttons/PbButton";
import {useModal, closeModal, buildModal} from "../../common/modals/Modal";
import * as PropService from "../../../shared/services/PropBuilderService";
import {translate} from "../../../shared/services/LocalizationService";

const CustomPropBuilder = ({game, type, onPropSaved}) => {
    const {setModal} = useModal();
    const [isSaving, setSaving] = useState(false);
    const [prop, setProp] = useState({
        subject: "CUSTOM",
        type: type
    });

    const onDescriptionUpdated = (description) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                description: description
            }
        });
    }

    const onHintUpdated = (hint) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                hint: hint
            }
        });
    }

    const onValueUpdated = (value) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                value: value
            }
        });
    }

    const cancel = () => {
        closeModal(setModal);
    }

    const saveProp = () => {
        setSaving(true);
        prop.gameId = game.id;
        PropService.saveProp(prop).then(result => {
                onPropSaved(result);
                closeModal(setModal);
            },
            error => {
                const modal = buildModal("Error", "Couldn't add prop.");
                setModal(modal);
                console.error(error);
            }
        );
    }

    const isSaveDisabled = !prop.type || !prop.description
        || (prop.type === "OVER_UNDER" && !prop.value);

    return (
        <div className="props-modal-body prop-builder">
            <div className="props-modal-title">
                {`${translate(prop.type)} Prop Builder`}
            </div>
            <div className="props-modal-content">
                Note: This prop is not eligible for live updates and must be manually resolved by the game admin.
            </div>
            <div className="field-row">
                <label>Prop</label>
                <div className="row-right">
                    <textarea className="row-right" rows="3" wrap="soft" value={prop.description || ""}
                              onChange={(e) => onDescriptionUpdated(e.target.value)}></textarea>
                </div>
            </div>
            <div className="field-row">
                <label>Hint</label>
                <div className="row-right">
                    <textarea className="row-right" rows="4" wrap="soft" value={prop.hint || ""}
                              onChange={(e) => onHintUpdated(e.target.value)}></textarea>
                </div>
            </div>

            <div className="props-modal-btns">
                <PbButton onClick={cancel} label="Cancel" isLoading={isSaving} btnClass="cancel"></PbButton>
                <PbButton onClick={saveProp} label="Save" isLoading={isSaving} isDisabled={isSaveDisabled}></PbButton>
            </div>
        </div>
    )
}

CustomPropBuilder.propTypes = {
    game: PropTypes.object.isRequired,
    type: PropTypes.object.isRequired,
    onPropSaved: PropTypes.func.isRequired
}

export default CustomPropBuilder;