import React from 'react'
import PropTypes from "prop-types";

import ResultsTableRow from './ResultsTableRow.jsx';
import PbContainer from "../../../common/containers/PbContainer";
import {getUserId} from "../../../../shared/services/AuthService";
import DateService from "../../../../shared/services/DateService";

const getName = (entry) => {
    let name = entry.firstName;
    if (entry && entry.lastName) {
        name = name + " " + entry.lastName.charAt(0) + ".";
    }
    return name;
}

export const RESULTS_VIEW_ALL = 'VIEW_ALL';
export const RESULTS_VIEW_RESOLVED = 'VIEW_RESOLVED';
export const RESULTS_VIEW_UNRESOLVED = 'VIEW_UNRESOLVED';

export const getPropsByView = (ticket, viewMode) => {
    const props = [];
    ticket.games.forEach(game => {
        game.props.sort((a, b) => {
            if (a.type < b.type) {
                return -1;
            }
            if (a.type > b.type) {
                return 1;
            }
            return 0;
        });

        game.props.map(prop => {
            prop.game = game;
            props.push(prop);
        })
    });

    if (viewMode === RESULTS_VIEW_RESOLVED) {
        return props.filter((prop) => prop.answer);
    } else if (viewMode === RESULTS_VIEW_UNRESOLVED) {
        return props.filter((prop) => !prop.answer);
    }
    return props;
}

const ResultsTable = ({viewMode, ticket}) => {

    const currentUserId = getUserId();
    const myEntry = ticket.entries.find((entry) => entry.userId === currentUserId);
    const otherEntries = ticket.entries.filter((entry) => entry.userId !== currentUserId)
        .sort((a, b) => {
            if (a.amountCorrect > b.amountCorrect) {
                return -1;
            }
            if (a.amountCorrect < b.amountCorrect) {
                return 1;
            }
            return 0;
        });

    let filteredProps = getPropsByView(ticket, viewMode);

    if (filteredProps.length === 0) {
        return <div></div>;
    }

    const getNameScoreLabel = (name, score) => {
        return (
            <div className="results-name-score-label">
                <div className="results-header-name">
                    {name}
                </div>
                <div className="results-header-score">
                    {score}
                </div>
            </div>
        );
    }

    const otherEntriesNames = otherEntries.map((entry) => {
        return (
            <th className="results-entry-col header" key={entry.userId}>
                {getNameScoreLabel(getName(entry), entry.amountCorrect)}
            </th>
        );
    });

    const otherEntriesScores = otherEntries.map((entry) => {
        return (
            <th className="results-entry-col" key={entry.userId}>
                {entry.amountCorrect}
            </th>
        );
    });

    const getHeaderRow = () => {
        return (
            <tr className="results-header-row">
                <th className="results-prop-col">Prop</th>
                <th className="results-answer-col">Result</th>
                <th className="results-my-picks-col">
                    {getNameScoreLabel('My Picks', myEntry ? (myEntry.amountCorrect || 0) : "-")}
                </th>
                {otherEntriesNames}
            </tr>
        );
    }

    const getScoresRow = () => {
        return (
            <tr className="results-scores-row">
                <th className="results-prop-col">Score</th>
                <th className="results-answer-col"></th>
                <th className="results-my-picks-col">{myEntry ? (myEntry.amountCorrect || 0) : "-"}</th>
                {otherEntriesScores}
            </tr>
        )
    }

    const rows = [];
    ticket.games.forEach((game) => {
        const propsForGame = filteredProps.filter((prop) => prop.game.id === game.id);
        if (ticket.games.length > 1 && propsForGame.length > 0) {
            rows.push(<tr>
                <td className="results-game-header" colSpan={1000}>
                    {game.name}: {DateService.parseDate(game.startTime)}
                </td>
            </tr>);
        }
        propsForGame.forEach((prop) =>
            rows.push(
                <ResultsTableRow
                    key={prop.id}
                    prop={prop}
                    myPick={myEntry ? myEntry.picks[prop.key] : "-"}
                    otherPicks={otherEntries}>
                </ResultsTableRow>
            ));
    })

    const showFooterRow = rows.length > 5;

    return (
        <div>
            <div className="results-table">
                <table className="table">
                    <thead>
                    {getHeaderRow()}
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                    <tfoot>
                    {showFooterRow && getScoresRow()}
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

ResultsTable.propTypes = {
    viewMode: PropTypes.string.isRequired,
    ticket: PropTypes.object.isRequired
};

export default ResultsTable;