import React from 'react'
import PropTypes from "prop-types";
import PropRadio from "../../common/fields/PropRadio";
import PropType from "../../../shared/constants/PropType";
import {PropHint} from "../../common/widgets/PropHint";

const getOptions = (prop, game) => {
    const type = prop.type;

    if (type === PropType.OVER_UNDER) {
        return [
            {label: "Under", value: "Under"},
            {label: "Over", value: "Over"}
        ];
    } else if (type === PropType.YES_NO) {
        return [
            {label: "Yes", value: "Yes"},
            {label: "No", value: "No"}
        ];
    } else if (type === PropType.ODD_EVEN) {
        return [
            {label: "Odd", value: "Odd"},
            {label: "Even", value: "Even"}
        ];
    } else if (type === PropType.TEAM) {
        if (prop.stat === "SPREAD") {
            const awayVal = "" + (0 - prop.value);
            return [
                {label: game.awayTeam + "\n" + (awayVal <= 0 ? "" : "+") + awayVal, value: game.awayTeam},
                {label: game.homeTeam + "\n" + prop.value, value: game.homeTeam}
            ];
        }
        return [
            {label: game.awayTeam, value: game.awayTeam},
            {label: game.homeTeam, value: game.homeTeam}
        ];
    } else {
        return ["Invalid Type"];
    }
}

const PropRow = ({propData, game, currentValue, onPropUpdated}) => {

    const isText = propData.type === PropType.TEXT;
    const isToggle = !isText;

    const options = getOptions(propData, game);

    const onUpdate = (value) => {
        onPropUpdated(propData, value);
    }

    const showLiveValue = !!propData.liveValue;

    const showPropValue = !!propData.value && propData.stat !== "SPREAD";

    return (
        <div className="prop-row">
            <div className="prop-desc">
                <div>
                    {propData.description}
                    {propData.hint &&
                        <PropHint prop={propData}></PropHint>
                    }
                </div>
            </div>
            {showLiveValue && <div className="prop-live-value">Live: {propData.liveValue}</div>}
            {isText &&
                <input className="form-control prop-select" type="text"
                       value={currentValue}
                       onChange={(e) => onUpdate(e.target.value)}></input>
            }
            {isToggle &&
                <PropRadio updateValue={onUpdate}
                           options={options}
                           currentValue={currentValue}
                           propLine={showPropValue ? propData.value : null}
                ></PropRadio>
            }
        </div>
    )
}

PropRow.propTypes = {
    propData: PropTypes.object.isRequired,
    game: PropTypes.object.isRequired,
    currentValue: PropTypes.any,
    onPropUpdated: PropTypes.func.isRequired
};

export default PropRow;
