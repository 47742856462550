import React from "react";
import PbContainer from "../../common/containers/PbContainer";

const GameInfo = ({ticket}) => {
    return (
        <div>
            {ticket.inviteMessage &&
                <PbContainer label="Note from Admin" collapsible={true}>
                    <div className="prop-entry-instructions" dangerouslySetInnerHTML={{__html: ticket.inviteMessage}}></div>
                </PbContainer>
            }
            <PbContainer label="Instructions" collapsible={true}>
                <div className="prop-entry-instructions">
                    <ul>
                        <li>Enter your selections below. Press <b>Save Props</b> at the bottom of the page to submit.
                        </li>
                        <li>Make sure to send your Buy-In of ${ticket.cost} to your game
                            admin {ticket.adminName} ({ticket.adminEmail}) before the game starts!
                        </li>
                        <li>If the game admin chooses to charge an Admin Fee, the fee will be taken from the total pot.
                            No extra amount is owed on top of the Buy-In
                        </li>
                        <li>After you make your picks, invite others to join under the <b>Invite</b> tab in the game
                            lobby.
                        </li>
                        <li>Once the game starts, results will be updated live. View the live game under <b>My Active
                            Games</b> on the dashboard.
                        </li>
                        <li>Some props have a hint. Click on <a><i className="far fa-question-circle"></i></a> to get
                            extra help.
                        </li>
                    </ul>
                </div>
            </PbContainer>
        </div>
    )
}

export default GameInfo;