import React from 'react'
import PropTypes from "prop-types";
import PropValuePill from "../../../common/widgets/PropValuePill";
import {PropHint} from "../../../common/widgets/PropHint";
import PropSubject from "../../../../shared/constants/PropSubject";
import PropType from "../../../../shared/constants/PropType";

export const ResultsTableRow = ({prop, myPick, otherPicks}) => {
    const propKey = prop.key;
    const propName = prop.description;
    const propResult = prop.answer;
    const propValue = prop.value;
    let propLiveValue = prop.liveValue;
    if (propLiveValue === null && prop.subject !== PropSubject.CUSTOM && prop.type === PropType.OVER_UNDER) {
        propLiveValue = "--";
    }

    const evaluatePropResultClass = (cellClass, answer) => {
        if (propResult) {
            if (answer && propResult.toUpperCase() === answer.toUpperCase()) {
                cellClass += 'correct-prop';
            } else {
                cellClass += 'incorrect-prop';
            }
        }
        return cellClass;
    }

    const myPickCell = () => {
        let cellClass = 'results-my-picks-col result ';
        cellClass = evaluatePropResultClass(cellClass, myPick);
        return (<td className={cellClass}>{myPick}</td>);
    };


    const otherPickCells = otherPicks.map((entry) => {
        const answer = entry.picks[propKey];
        let cellClass = 'results-entry-col ';
        cellClass = evaluatePropResultClass(cellClass, answer);

        return (
            <td className={cellClass} key={entry.userId}>
                {answer}
            </td>
        );
    });

    const propColumn = (
        <div>
            {propName}
            {prop.hint &&
                <PropHint prop={prop}></PropHint>
            }
            {propValue &&
                <div className="prop-value-row">
                    <PropValuePill label="o/u" value={propValue}></PropValuePill>
                </div>
            }
        </div>
    );

    const propResultColumn = (
        <div>
            {propResult}
            {(propLiveValue || propLiveValue === 0)  &&
                <div className="prop-value-row">
                    <PropValuePill label="LIVE" value={propLiveValue} isLive={true}></PropValuePill>
                </div>
            }
        </div>
    );

    return (
        <tr>
            <td className="results-prop-col">{propColumn}</td>
            <td className="results-answer-col">{propResultColumn}</td>
            {myPickCell()}
            {otherPickCells}
        </tr>
    );
}

ResultsTableRow.propTypes = {
    prop: PropTypes.object.isRequired,
    myPick: PropTypes.string,
    otherPicks: PropTypes.array.isRequired
};

export default ResultsTableRow;
