import {get} from "./ApiService";

export const getServerVersion = () => {
    return localStorage.getItem('serverVersion');
}

export const downloadConfiguration = () => {
    get('api/configuration').then(response => {
            localStorage.setItem('serverVersion', response.appVersion);
        },
        error => {
            console.log(error);
        }
    );
}

export const isWsDebugMode = () => {
    return localStorage.getItem('wsDebug') === 'true';
}