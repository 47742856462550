import React from 'react'

export const DashboardContent = () => {
    return <div>
        <div className="info-content-header">
            What is Props Buddy?
        </div>
        <div className="info-content-body">
            Similar to a Proline Props ticket, Props Buddy allows you to pair together a
            bunch of props bets... the difference is that Props Buddy lets you play against
            your "buddies". One person creates a ticket by choosing one or more games on the schedule
            and adds their choice of props. They can then invite all their friends to join. Then follow
            along with the game, keep an eye on the stats and hope your ticket doesn't bust. Props Buddy's
            goal is to make any game more interesting.
            <br/><br/>
            Year after year at Ryan's annual Super Bowl party, props bets on sheets of paper were passed
            around and marked by hand at the end of the game. One year, Ryan decided to go electronic,
            and from there Props Buddy has been evolving and is being used year round for any sort of game.
        </div>

        <div className="info-content-header">
            What is a prop bet?
        </div>
        <div className="info-content-body">
            A <b>prop bet</b>, or more formally, a proposition bet, is a bet that something will
            or will not happen in a game, an event that is not necessarily just the end result of the
            game. The most popular props bets are:<br/><br/>
            <ul>
                <li><b>Over/Under</b>: The result of a specific stat is either over or under the target
                    number. The target number is commonly a half number (i.e. 7.5) in order to always
                    guarantee
                    a that either over or under is satisfied (no ties). Ex. Total Interceptions Over/Under
                    1.5.
                </li>
                <br/>
                <li><b>Yes/No</b>: This one is simple, yes or no, will the event occur or not. Ex. Will both
                    teams
                    score in the first period.
                </li>
                <br/>
                <li><b>Team</b>: Choose the team that you think the prop bet will apply to. Ex. Which team
                    will score
                    first.
                </li>
                <br/>
                <li><b>Odd/Even</b>: Similar to Over/Under, based off a specific statistic, but predicting
                    will the end
                    number be either an odd or even number. Ex. Total Strikeouts for both teams.
                </li>
            </ul>
        </div>

    </div>
}