import React, {useState} from 'react'
import PropTypes from "prop-types";
import PbButton from "../buttons/PbButton";
import {useSearchParams} from "react-router-dom";
import PbListPageControl from "../list/PbListPageControl";

const FIRST_LEVEL_NAV = 'NAV_1';
export const SECOND_LEVEL_NAV = 'NAV_2';

const PbTabContainer = ({tabs, navLevel = FIRST_LEVEL_NAV, showNavButtons = false}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    let selectedTabParam = Number(searchParams.get("tab"));
    if (navLevel !== FIRST_LEVEL_NAV || isNaN(selectedTabParam) ||
        selectedTabParam < 0 || selectedTabParam > tabs.length - 1) {
        selectedTabParam = 0;
    }

    const [selectedTab, setSelectedTab] = useState(selectedTabParam);

    const updateTab = (tabIndex) => {
        setSelectedTab(tabIndex);
        if (navLevel === FIRST_LEVEL_NAV ) {
            searchParams.set("tab", tabIndex);
            setSearchParams(searchParams);
        }
    }

    const tabBar = tabs.map((tab, index) =>
        <div className={`nav-bar-row-item ${selectedTab === index ? "selected" : ""}`}
             key={index} onClick={() => updateTab(index)}>
            <i className={tab.tabIcon}></i>
            {tab.tabName}
        </div>);

    if (navLevel === FIRST_LEVEL_NAV) {
        return (
            <div className="pb-tab-container">
                <div className="nav-bar-row">{tabBar}</div>
                <div>{tabs[selectedTab].tabContent}</div>
                {showNavButtons && tabs.length > 1 &&
                    <PbListPageControl
                        onPageChanged={(value) => {setSelectedTab(value - 1); window.scrollTo(0, 0)}}
                        totalPages={tabs.length}
                        currentPage={selectedTab + 1}
                    ></PbListPageControl>
                }
            </div>
        );
    } else {
        return (
            <div className="pb-tab-container">
                <div className="nav-bar-row nav-level-2">{tabBar}</div>
                <div>{tabs[selectedTab].tabContent}</div>
                {showNavButtons && tabs.length > 1 &&
                    <PbListPageControl
                        onPageChanged={(value) => {setSelectedTab(value - 1); window.scrollTo(0, 0)}}
                        totalPages={tabs.length}
                        currentPage={selectedTab + 1}
                    ></PbListPageControl>
                }
            </div>
        );
    }


}

PbTabContainer.propTypes = {
    tabs: PropTypes.array.isRequired
};

export default PbTabContainer;