import React, {useState} from 'react'
import {put} from "../../../../shared/services/ApiService";
import PropTypes from "prop-types";
import ErrorPanel from "../../../common/error/ErrorPanel";
import LabeledTextArea from "../../../common/fields/LabeledTextArea";
import PbButton from "../../../common/buttons/PbButton";
import LabeledDateField from "../../../common/fields/LabeledDateField";
import LabeledDropdown from "../../../common/fields/LabeledDropdown";
import {PAYOUT_FORMATS} from "../../../../shared/constants/TicketConstants";

const TicketDetailsEditor = ({ticket}) => {
    const [isSaving, setSaving] = useState(false);
    const [updatedTicket, setUpdatedTicket] = useState(ticket);
    const [request, setRequest] = useState({});
    const [errors, setErrors] = useState(null);

    const onChange = (fieldName, value) => {
        setUpdatedTicket(prevTicket => {
                return {
                    ...prevTicket,
                    [fieldName]: value
                }
            }
        );
        setRequest(prevTicket => {
                return {
                    ...prevTicket,
                    [fieldName]: value
                }
            }
        );
    }

    const saveTicket = () => {
        setSaving(true);
        put(`api/tickets/${ticket.id}`, request).then(() => {
                setSaving(false);
                setRequest({});
            },
            error => {
                setErrors(error);
                console.error(error);
            });
    }

    const minDateValue = new Date();
    minDateValue.setHours(0, 0, 0);

    return (
        <div className="ticket-details-editor">
            <div className="games-header">Ticket Details Editor</div>
            <ErrorPanel errors={errors}></ErrorPanel>
            <div className="pb-field-row">
                <LabeledDateField name="startTime" label="Lock Time" value={updatedTicket.startTime}
                                  minValue={minDateValue} onChange={onChange}></LabeledDateField>
                <LabeledDropdown name="payoutFormat" label="Payout Format" options={PAYOUT_FORMATS}
                                 value={updatedTicket.payoutFormat} onChange={onChange}></LabeledDropdown>
            </div>
            <LabeledTextArea name="inviteMessage" onChange={(value) => onChange(value)}
                             value={updatedTicket.inviteMessage}
                             label="Invite Message"></LabeledTextArea>
            <div className="props-modal-btns">
                <PbButton onClick={() => saveTicket()} label="Update Ticket" isLoading={isSaving}></PbButton>
            </div>
        </div>
    )
}

TicketDetailsEditor.propTypes = {
    ticket: PropTypes.object.isRequired
}

export default TicketDetailsEditor;