import PropTypes from "prop-types";
import React from "react";
import {buildModal, useModal} from "../modals/Modal";
import {HelpIcon} from "./HelpIcon";

export const PropHint = ({prop}) => {
    const {setModal} = useModal();

    const onClick = (prop) => {
        const modalBody = (
            <div>
                <div className="prop-hint-prop">
                    {prop.description}
                </div>
                <div className="prop-hint-explanation">
                    {prop.hint}
                </div>
            </div>
        )

        const modal = buildModal("Prop Help", modalBody)
        setModal(modal);
    }


    return (
        <span className="prop-hint">
            <HelpIcon onClick={() => onClick(prop)}></HelpIcon>
        </span>
    );
}

PropHint.propTypes = {
    prop: PropTypes.object.isRequired
};