import React, {useState} from 'react'
import PropTypes from "prop-types";
import DateService from "../../../shared/services/DateService";
import {getUserId} from "../../../shared/services/AuthService";
import {GAME_RESULTS_KIOSK} from "../../../shared/constants/RouteContants";
import {useNavigate} from "react-router-dom";
import PbRadio from "../../common/buttons/PbRadio";

export const TIMELINE_VIEW_ALL = 'VIEW_ALL';
export const TIMELINE_VIEW_RESULTS = 'VIEW_RESULTS';

export const ResultsTimeline = ({ticket, defaultViewMode = TIMELINE_VIEW_RESULTS}) => {
    const [viewMode, setViewMode] = useState(defaultViewMode);
    const navigate = useNavigate();

    const getStyle = (event) => {
        if (event.eventType === "STAT_UPDATE") {
            return "stat-update";
        } else if (event.eventType === "PROP_RESOLVED") {
            return "prop-resolved";
        } else if (event.eventType === "GAME_START_END") {
            return "game-start-end"
        }
        return "";
    }

    const timeline = ticket.timeline;

    let timelineView;
    if (viewMode === TIMELINE_VIEW_RESULTS) {
        timelineView = timeline.filter((event) => event.eventType === 'PROP_RESOLVED');
    } else {
        timelineView = timeline;
    }

    const timelineEvents = timelineView.map((event, index) =>
        <div className={`event ${getStyle(event)}`} key={index}>
            <span className={`event-message ${getStyle(event)}`}>{event.message}</span>
            <span className="event-time">{DateService.parseDate(event.created, 'h:mm:ss')}</span>
        </div>
    );

    const showOptions = [
        {label: "Results only", value: TIMELINE_VIEW_RESULTS},
        {label: "All updates", value: TIMELINE_VIEW_ALL}
    ]

    return (
        <div>
            <div className="results-timeline">
                <div className="results-timeline-filter">
                    <PbRadio options={showOptions} currentValue={viewMode}
                             onSelect={(value) => setViewMode(value)}></PbRadio>
                </div>
                {timelineEvents}
            </div>
            {ticket.adminId === getUserId() &&
                <div>
                    <a style={{margin: 5 + 'px'}}
                       onClick={() => navigate(GAME_RESULTS_KIOSK + ticket.code)}>Open Kiosk Mode...</a>
                </div>
            }
        </div>
    );
}

ResultsTimeline.propTypes = {
    ticket: PropTypes.object.isRequired,
    viewMode: PropTypes.string
};

export default ResultsTimeline;