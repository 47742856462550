import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import PropSubject from "../../../shared/constants/PropSubject";
import PbButton from "../../common/buttons/PbButton";
import {useModal, closeModal, buildModal} from "../../common/modals/Modal";
import * as PropService from "../../../shared/services/PropBuilderService";
import PbRadio from "../../common/buttons/PbRadio";
import PropRadio from "../../common/fields/PropRadio";
import Sport from "../../../shared/constants/Sport";

const getStatOptions = (propMetadata) => {
    const statGroups = new Map();
    propMetadata.forEach((item) => {
        const group = item.statGroup || "Other";
        const groupedStats = statGroups.get(group);
        if (!groupedStats) {
            statGroups.set(group, [item]);
        } else {
            groupedStats.push(item);
        }
    });

    if (statGroups.size > 1) {
        const statOptions = [];
        statGroups.forEach((stats, group) => {
            const options = stats.sort((a, b) => {
                return a.description < b.description ? -1 : 1;
            }).map((option, index) => {
                return <option key={index} value={option.stat}>{option.description}</option>;
            });
            statOptions.push(
                <optgroup label={group}>
                    {options}
                </optgroup>
            );
        });
        return statOptions;
    } else {
        return propMetadata.sort((a, b) => {
            return a.description < b.description ? -1 : 1;
        }).map((option, index) => {
            return <option key={index} value={option.stat}>{option.description}</option>;
        });
    }
}

const OverUnderPropBuilder = ({game, onPropSaved}) => {
    const {setModal} = useModal();

    const [isSaving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [propMetadata, setPropMetadata] = useState([]);
    const teams = [
        {label: game.homeTeam, value: game.homeTeam},
        {label: game.awayTeam, value: game.awayTeam}
    ]
    const [team, setTeam] = useState("");
    const [players, setPlayers] = useState([]);
    const [prop, setProp] = useState({
        type: "OVER_UNDER",
        subject: "GAME"
    });

    const fetchMetadata = () => {
        PropService.getPropMetadata(game.sport, prop.subject).then(response => {
            setPropMetadata(response);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (game.sport !== Sport.CUSTOM) {
            fetchMetadata();
        } else {
            setProp({
                type: "OVER_UNDER",
                subject: Sport.CUSTOM
            });
            setLoading(false);
        }
    }, [prop.subject]);

    if (loading) {
        return (
            <div className="props-modal-body prop-builder">
                <div className="page-loader"></div>
            </div>
        );
    }

    const onStatUpdated = (stat) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                stat: stat
            }
        });
    }

    const onValueUpdated = (value) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                value: value
            }
        });
    }

    const onSubjectUpdated = (subject) => {
        setTeam("");
        setProp({
            type: "OVER_UNDER",
            subject: subject
        });
    }

    const onPlayerUpdated = (playerId) => {
        const player = players.find(p => p.id === playerId);

        setProp(prevProp => {
            return {
                ...prevProp,
                playerName: player ? player.fullName : null,
                externalPlayerId: player ? player.id : null
            }
        });
    }

    const onDescriptionUpdated = (description) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                description: description
            }
        });
    }

    const onHintUpdated = (hint) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                hint: hint
            }
        });
    }

    const getMetadataForStat = () => {
        if (!prop || !prop.stat) {
            return;
        }

        return propMetadata.find(metadata => metadata.stat === prop.stat)
    }

    const isPosition = (player) => {
        const metadata = getMetadataForStat();
        if (!metadata || !metadata.playerPositions) {
            return true;
        }

        return metadata.playerPositions.split(',').includes(player.position);
    }
    const getPlayers = () => {
        return players
            .filter((player) => isPosition(player))
            .sort((a, b) => {
                if (a.fullName < b.fullName) {
                    return -1;
                }
                return 1;
            })
            .map((option, index) => {
                return <option key={index} value={option.id}>{option.fullName}, {option.position}</option>;
            });
    }

    const onTeamToggled = (value) => {
        setTeam(value);

        if (prop.subject === PropSubject.PLAYER) {
            const activeTeam = value === teams[0].value ? game.externalGame.homeTeam : game.externalGame.awayTeam;
            setPlayers(activeTeam.roster);
            onPlayerUpdated(null);
        }

        if (prop.subject === PropSubject.TEAM) {
            const team = game.externalGame.homeTeam.nickName === value ? "HOME" : "AWAY";
            setProp(prevProp => {
                return {
                    ...prevProp,
                    team: team
                }
            });
        }
    }

    const statOptions = getStatOptions(propMetadata);

    const cancel = () => {
        closeModal(setModal);
    }

    const saveProp = () => {
        setSaving(true);
        prop.gameId = game.id;

        PropService.saveProp(prop).then(result => {
                onPropSaved(result);
                closeModal(setModal);
            },
            error => {
                const modal = buildModal("Error", "Couldn't add prop.");
                setModal(modal);
                console.error(error);
            }
        );
    }

    const isSaveEnabled = () => {
        if (prop.subject === PropSubject.GAME) {
            return !!prop.stat && !!prop.value;
        } else if (prop.subject === PropSubject.TEAM) {
            return !!prop.team && !!prop.stat && !!prop.value;
        } else if (prop.subject === PropSubject.PLAYER) {
            return !!prop.playerName && !!prop.stat && !!prop.value;
        }

        return !!prop.value && !!prop.description;
    }

    const subjectOptions = [];
    if (game.sport !== Sport.CUSTOM) {
        subjectOptions.push(
            {label: "Game", value: "GAME"},
            {label: "Team", value: "TEAM"},
            {label: "Player", value: "PLAYER"},
            {label: "Custom", value: "CUSTOM"}
        );
    }

    return (
        <div className="props-modal-body prop-builder">
            <div className="props-modal-title">
                Over/Under Prop Builder
            </div>
            {subjectOptions.length > 1 &&
                <div className="field-row">
                    <PbRadio currentValue={prop.subject} options={subjectOptions}
                             onSelect={onSubjectUpdated}></PbRadio>
                </div>
            }
            {prop.subject === PropSubject.CUSTOM &&
                <div className="props-modal-content">
                    Note: Custom props are not eligible for live stat updates.
                </div>
            }
            {prop.subject !== 'CUSTOM' &&
                <div className="field-row">
                    <label>Stat</label>
                    <select className="row-right prop-dropdown" value={prop.stat || ""}
                            onChange={(e) => onStatUpdated(e.target.value)}>
                        <option value="" disabled hidden></option>
                        {statOptions}
                    </select>
                </div>
            }
            {(prop.subject === 'PLAYER' || prop.subject === 'TEAM') &&
                <div className="field-row">
                    <label>Team</label>
                    <div className="row-right">
                        <PropRadio updateValue={onTeamToggled} currentValue={team} options={teams}></PropRadio>
                    </div>
                </div>
            }
            {prop.subject === 'PLAYER' &&
                <div className="field-row">
                    <label>Player</label>
                    <select className="row-right prop-dropdown" value={prop.externalPlayerId || ""}
                            onChange={(e) => onPlayerUpdated(e.target.value)}>
                        <option value="" disabled hidden></option>
                        {getPlayers()}
                    </select>
                </div>
            }
            {prop.subject === 'CUSTOM' &&
                <div className="field-row">
                    <label>Prop</label>
                    <div className="row-right">
                    <textarea className="row-right" rows="3" wrap="soft" value={prop.description || ""}
                              onChange={(e) => onDescriptionUpdated(e.target.value)}></textarea>
                    </div>
                </div>
            }
            <div className="field-row">
                <label>O/U Value</label>
                <input className="row-right" type="number" value={prop.value || ""}
                       onChange={(e) => onValueUpdated(e.target.value)}></input>
            </div>
            <div className="field-row">
                <label>Hint</label>
                <div className="row-right">
                    <textarea className="row-right" rows="4" wrap="soft" value={prop.hint || ""}
                              onChange={(e) => onHintUpdated(e.target.value)}></textarea>
                </div>
            </div>

            <div className="props-modal-btns">
                <PbButton onClick={cancel} label="Cancel" isLoading={isSaving} btnClass="cancel"></PbButton>
                <PbButton onClick={() => saveProp()} isDisabled={!isSaveEnabled()} isLoading={isSaving}
                          label="Save"></PbButton>
            </div>
        </div>
    )
}

OverUnderPropBuilder.propTypes = {
    game: PropTypes.object.isRequired,
    onPropSaved: PropTypes.func.isRequired
}

export default OverUnderPropBuilder;