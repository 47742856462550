import React from 'react'
import PropTypes from "prop-types";

const PbRadio = ({options, currentValue, onSelect, hideEmpty}) => {

    const optionElements = options
        .filter((option) => !hideEmpty || option.count > 0)
        .map((option, index) =>
            <div key={index}
                 onClick={() => onSelect(option.value)}
                 className={`pb-radio-item ${currentValue === option.value ? 'selected' : ''}`}>
                <div>{option.label} {!!option.count || option.count === 0 ? `(${option.count})` : ''}</div>
            </div>
        )

    return (
        <div className="pb-radio">
            {optionElements}
        </div>
    )
}

PbRadio.propTypes = {
    options: PropTypes.array.isRequired,
    currentValue: PropTypes.any.isRequired,
    onSelect: PropTypes.func.isRequired,
    hideEmpty: PropTypes.bool
};

export default PbRadio;