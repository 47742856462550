import React from 'react'
import PropTypes from "prop-types";
import PbContainer from "../../common/containers/PbContainer";
import {getUserId} from "../../../shared/services/AuthService";
import PbListRow from "../../common/list/PbListRow";
import PbList from "../../common/list/PbList";

const getWinnerCount = (payoutFormat) => {
    if (payoutFormat === 'TOP_3') {
        return 3;
    } else if (payoutFormat === 'TOP_2') {
        return 2;
    }
    return 1;
}

const Leaderboard = ({ticket}) => {
    let position = 0;
    let count = 0;
    let currentCorrect = 99999;

    const currentUserId = getUserId();
    const isPreGame = ticket.status === 'PRE_GAME';

    const entryRows = ticket.entries
        .sort((a, b) => {
            if (a.amountCorrect > b.amountCorrect) {
                return -1;
            }
            if (a.amountCorrect < b.amountCorrect) {
                return 1;
            }
            return 0;
        })
        .map((entry) => {
            count++;
            if (entry.amountCorrect < currentCorrect) {
                currentCorrect = entry.amountCorrect;
                position = count;
            }

            // Only show winner once at least one prop is correct
            const isWinner = currentCorrect > 0 && position <= getWinnerCount(ticket.payoutFormat);

            return (
                <PbListRow key={entry.userId}>
                    {!isPreGame && <div className="results-leaderboard-position">{position}</div>}
                    <div className="results-leaderboard-status">
                        {isWinner && entry.paid && <i className="fas fa-trophy"></i>}
                        {!entry.paid && <i className="fas fa-search-dollar" title="UNPAID"></i>}
                    </div>
                    <div className={`results-leaderboard-name ${entry.userId === currentUserId ? 'mine' : ''}`}>
                        {entry.firstName} {entry.lastName}
                    </div>
                    {!isPreGame && <div className="results-leaderboard-score">{entry.amountCorrect || 0}</div>}
                </PbListRow>
            )
        });

    const entryCount = ticket.entries.length;

    entryRows.unshift(
        <PbListRow>
            {!isPreGame && <div className="results-leaderboard-position">POS</div>}
            <div className="results-leaderboard-status"></div>
            <div className="results-leaderboard-name">NAME</div>
            {!isPreGame && <div className="results-leaderboard-score">SCORE</div>}
        </PbListRow>
    );

    return <div className="results-leaderboard">
        {isPreGame &&
            <div className="results-leaderboard-entries-row">
                Entry Count: {entryCount} (Current Pot ${entryCount * ticket.cost})
            </div>
        }
        <PbList rows={entryRows}></PbList>
        {!isPreGame &&
            <div className="results-leaderboard-entries-row">
                Entry Count: {entryCount} (Current Pot ${entryCount * ticket.cost})
            </div>
        }
        <div className="disclaimer-row">
            <i className="fas fa-search-dollar"></i><span> - unpaid</span>
        </div>
    </div>
}

Leaderboard.propTypes = {
    ticket: PropTypes.object.isRequired
};

export default Leaderboard;