import React from 'react'
import PropBuilder from "../../prop/builder/PropBuilder";
import MessageDrafter, {MESSAGE_TYPE_ALL_PARTICIPANTS} from "../lobby/MessageDrafter";
import PbTabContainer, {SECOND_LEVEL_NAV} from "../../common/containers/PbTabContainer";
import PaymentManager from "./PaymentManager";
import InviteManager from "./invite/InviteManager";
import TicketDetailsEditor from "./details/TicketDetailsEditor";
import TicketManager from "./TicketManager";


export const TicketAdminView = ({ticket}) => {
    let tabs = [];

    if (ticket.status === 'DRAFT') {
        const gameTabs = ticket.games.map((game, index) => {
            return ({tabName: game.name, tabContent: <PropBuilder key={index} game={game}></PropBuilder>})
        });
        return (
            <div>
                <PbTabContainer tabs={gameTabs} navLevel={SECOND_LEVEL_NAV} showNavButtons={true}></PbTabContainer>
                <TicketManager ticket={ticket}></TicketManager>
            </div>
        )
    } else if (ticket.status === 'PRE_GAME') {
        tabs = [
            {
                tabName: "Status", tabIcon: "fas fa-ticket-alt",
                tabContent: <TicketManager ticket={ticket}></TicketManager>
            },
            {
                tabName: "Details", tabIcon: "fas fa-edit",
                tabContent: <TicketDetailsEditor ticket={ticket}></TicketDetailsEditor>
            },
            {
                tabName: "Payment", tabIcon: "fas fa-dollar-sign",
                tabContent: <PaymentManager ticket={ticket}></PaymentManager>
            },
            {
                tabName: "Invites", tabIcon: "fas fa-envelope",
                tabContent: <InviteManager ticket={ticket}></InviteManager>
            },
            {
                tabName: "Messaging", tabIcon: "fas fa-comment",
                tabContent: <MessageDrafter ticket={ticket}
                                            messageType={MESSAGE_TYPE_ALL_PARTICIPANTS}></MessageDrafter>
            }];
    } else if (ticket.status === 'LIVE') {
        tabs = [
            {
                tabName: "Status", tabIcon: "fas fa-ticket-alt",
                tabContent: <TicketManager ticket={ticket}></TicketManager>
            },
            {
                tabName: "Details", tabIcon: "fas fa-edit",
                tabContent: <TicketDetailsEditor ticket={ticket}></TicketDetailsEditor>
            },
            {
                tabName: "Payment", tabIcon: "fas fa-dollar-sign",
                tabContent: <PaymentManager ticket={ticket}></PaymentManager>
            },
            {
                tabName: "Messaging", tabIcon: "fas fa-comment",
                tabContent: <MessageDrafter ticket={ticket}
                                            messageType={MESSAGE_TYPE_ALL_PARTICIPANTS}></MessageDrafter>
            }];
    } else if (ticket.status === 'POST_GAME' || ticket.status === 'COMPLETED') {
        tabs = [
            {
                tabName: "Status", tabIcon: "fas fa-ticket-alt",
                tabContent: <TicketManager ticket={ticket}></TicketManager>
            },
            {
                tabName: "Payment", tabIcon: "fas fa-dollar-sign",
                tabContent: <PaymentManager ticket={ticket}></PaymentManager>
            },
            {
                tabName: "Messaging", tabIcon: "fas fa-comment",
                tabContent: <MessageDrafter ticket={ticket}
                                            messageType={MESSAGE_TYPE_ALL_PARTICIPANTS}></MessageDrafter>
            }];
    } else if (ticket.status === 'ARCHIVED') {
        tabs = [
            {
                tabName: "Not Available", tabIcon: "fas fa-list-ol",
                tabContent: <div>Game archived. No longer available</div>
            },
        ]
    }

    return (
        <div>
            <PbTabContainer tabs={tabs} navLevel={SECOND_LEVEL_NAV}></PbTabContainer>
        </div>
    )
}