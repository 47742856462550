import React, {useEffect, useState} from 'react'
import {buildModal, useModal} from "../../../common/modals/Modal";
import {get} from "../../../../shared/services/ApiService";
import InvitedUsersList from "./InvitedUsersList";
import UninvitedUsersList from "./UninvitedUsersList";
import PbContainer from "../../../common/containers/PbContainer";

const InviteManager = ({ticket}) => {
    const {setModal} = useModal();

    const [loading, setLoading] = useState(true);
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [uninvitedUsers, setUninvitedUsers] = useState([]);

    const fetchInvites = () => {
        get(`api/tickets/${ticket.id}/invites`).then(result => {
                setInvitedUsers(result.filter(u => u.invited));
                setUninvitedUsers(result.filter(u => !u.invited));
                setLoading(false);
            },
            error => {
                const modal = buildModal("Error", "Failed to fetch invites.");
                setModal(modal);
                console.error(error);
            });
    }

    useEffect(() => {
        fetchInvites();
    }, []);

    if (loading) {
        return (
            <div className="page-loader"></div>
        );
    }


    return (
        <div>
            <PbContainer label="My Friends" collapsible={true}>
                <UninvitedUsersList ticket={ticket} uninvitedUsers={uninvitedUsers}
                                    onInvitesUpdated={() => fetchInvites()}
                ></UninvitedUsersList>
            </PbContainer>
            <PbContainer label="Invited Friends" collapsible={true}>
                <InvitedUsersList ticket={ticket} invitedUsers={invitedUsers}></InvitedUsersList>
            </PbContainer>
        </div>
    )
}

export default InviteManager;