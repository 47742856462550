import React from 'react'
import PropTypes from "prop-types";

const PropRadio = ({options, currentValue, updateValue, propLine}) => {
    return (
        <div className="prop-radio">
            <div className={`prop-radio-btn ${currentValue === options[0].value ? 'active' : ''}`}
                 onClick={() => updateValue(options[0].value)}>
                {options[0].label}
            </div>
            {propLine &&
                <div className={`prop-radio-line ${propLine.length > 5 ? 'line-small' : ''}`}>
                    {propLine}
                </div>}
            <div className={`prop-radio-btn ${currentValue === options[1].value ? 'active' : ''}`}
                 onClick={() => updateValue(options[1].value)}>
                {options[1].label}
            </div>
        </div>
    )
}

PropRadio.propTypes = {
    options: PropTypes.array.isRequired,
    currentValue: PropTypes.any,
    updateValue: PropTypes.func.isRequired,
    propLine: PropTypes.any
};

export default PropRadio;
  
  