const PLAYER = 'PLAYER';
const TEAM = 'TEAM';
const GAME = 'GAME';
const CUSTOM = 'CUSTOM';

class PropSubject {

    static get ALL_SUBJECTS() {
        return [PLAYER, TEAM, GAME, CUSTOM];
    }

    static get PLAYER() {
        return PLAYER;
    }

    static get TEAM() {
        return TEAM;
    }

    static get GAME() {
        return GAME;
    }

    static get CUSTOM() {
        return CUSTOM;
    }
}

export default PropSubject;