import React, {useState} from 'react';
import PbButton from '../../common/buttons/PbButton.jsx';
import {buildModal, useModal} from "../../common/modals/Modal";
import {useNavigate} from "react-router-dom";
import {GAME_LOBBY, HOME} from "../../../shared/constants/RouteContants";
import LabeledField from "../../common/fields/LabeledField";
import LabeledDateField from "../../common/fields/LabeledDateField";
import LabeledDropdown from "../../common/fields/LabeledDropdown";
import PbContainer from "../../common/containers/PbContainer";
import {post} from "../../../shared/services/ApiService";
import PbLabel from "../../common/labels/PbLabel";
import TicketBuilderGameItem from "./TicketBuilderGameItem";
import GameSchedule from "./GameSchedule";
import PbTabContainer from "../../common/containers/PbTabContainer";
import Sport from "../../../shared/constants/Sport";
import {getFirstName} from "../../../shared/services/AuthService";
import DateService from "../../../shared/services/DateService";
import CustomGameBuilder from "./CustomGameBuilder";
import {ADMIN_FEE_FORMATS, PAYOUT_FORMATS} from "../../../shared/constants/TicketConstants";

const defaultTicket = () => {
    const defaultTicket = {};

    const today = new Date();
    today.setDate(today.getDate());
    today.setHours(today.getHours() + 2);
    today.setMinutes(0);
    today.setMilliseconds(0);
    defaultTicket.startTime = today;

    defaultTicket.name = `${getFirstName()}'s ${DateService.parseDate(today, 'dddd')} Ticket`;
    defaultTicket.cost = 5;
    defaultTicket.addDefaultProps = true;
    defaultTicket.payoutFormat = "WINNER_TAKE_ALL";
    defaultTicket.adminFeeValue = 0;
    defaultTicket.adminFeeFormat = "NONE";
    defaultTicket.format = "PROPS";
    defaultTicket.games = [];


    return defaultTicket;
}

const TicketBuilder = () => {
    const [ticket, setTicket] = useState(defaultTicket());
    const [step, setStep] = useState(1);

    const {setModal} = useModal();
    const navigate = useNavigate();

    const onChange = (fieldName, value) => {
        setTicket(prevTicket => {
                return {
                    ...prevTicket,
                    [fieldName]: value
                }
            }
        );
    }

    const onSave = () => {
        setModal(buildModal("Ticket Builder",
            "Once you continue, you can no longer change the games for your ticket. Do you want to continue to prop creation?",
            () => {
                post('api/tickets', ticket).then(result => {
                        const modal = buildModal("Ticket Builder", "Ticket was created successfully. " +
                            "Confirm the default props or add additional props to your game.");
                        setModal(modal);
                        navigate(GAME_LOBBY + result.code);
                    },
                    error => {
                        const modal = buildModal("Error", "Failed to create game.");
                        setModal(modal);
                        console.error(error);
                    });
            }, "Continue",
            () => {},
            "Cancel"))

    }

    const addGame = (newGame) => {
        const games = [...ticket.games];
        games.push(newGame);
        setTicket(prevTicket => {
                return {
                    ...prevTicket,
                    games: games
                }
            }
        );
    }

    const updateGame = (index, updatedGame) => {
        const currentGames = [...ticket.games];
        currentGames[index] = updatedGame;
        setTicket(prevTicket => {
                return {
                    ...prevTicket,
                    games: currentGames
                }
            }
        );
    }

    const deleteGame = (index) => {
        const currentGames = [...ticket.games];
        currentGames.splice(index, 1);
        setTicket(prevTicket => {
                return {
                    ...prevTicket,
                    games: currentGames
                }
            }
        );
    };

    const games = ticket.games;

    const gameItems = games.map((game, index) => {
        return <TicketBuilderGameItem key={index} game={game}
                                      updateGame={(game) => updateGame(index, game)}
                                      deleteGame={() => deleteGame(index)}
        ></TicketBuilderGameItem>;
    });

    if (step === 1) {
        const minDateValue = new Date();
        minDateValue.setHours(0, 0, 0);
        return (
            <div className="props-page-body-dark">
                <label className="games-header">Ticket Builder</label>
                <PbContainer label="Ticket Details">
                    <div className="game-details">
                        <div className="pb-field-row">
                            <LabeledField name="name" label="Ticket Name" type="text" value={ticket.name}
                                          onChange={onChange}></LabeledField>
                            <LabeledDateField name="startTime" label="Lock Time" value={ticket.startTime}
                                              minValue={minDateValue} onChange={onChange}></LabeledDateField>
                        </div>
                        <div className="pb-field-row">
                            <LabeledDropdown name="payoutFormat" label="Payout Format" options={PAYOUT_FORMATS}
                                             value={ticket.payoutFormat} onChange={onChange}></LabeledDropdown>
                            <LabeledField name="cost" label="Entry Cost" type="number" value={ticket.cost}
                                          onChange={onChange}
                                          helpText="The game admin is responsible for collecting payment (if applicable)
                                          from all game entrants. Mark users paid in the ticket Admin section once the
                                          game has been created."
                            ></LabeledField>
                        </div>
                        <div className="pb-field-row">
                            <LabeledDropdown name="adminFeeFormat" label="Admin Fee Format" options={ADMIN_FEE_FORMATS}
                                             value={ticket.adminFeeFormat} onChange={onChange}></LabeledDropdown>
                            {ticket.adminFeeFormat !== 'NONE' &&
                                <LabeledField name="adminFeeValue" label="Admin Fee" type="number"
                                              value={ticket.adminFeeValue}
                                              onChange={onChange}></LabeledField>
                            }
                        </div>
                        {/*<div className="pb-field-row">*/}
                        {/*    <LabeledDropdown name="format" label="Ticket Format" options={ticketFormats}*/}
                        {/*                     value={ticket.format} onChange={onChange}></LabeledDropdown>*/}
                        {/*</div>*/}
                    </div>
                </PbContainer>
                <div className="pb-btn-row">
                    <PbButton label="Abandon" btnClass="secondary" onClick={() => navigate(HOME)}></PbButton>
                    <PbButton label="Continue" isDisabled={!ticket.name}
                              onClick={() => setStep(2)}></PbButton>
                </div>
            </div>
        )
    }

    const sportMap = [
        {
            label: "Hockey",
            type: Sport.HOCKEY
        },
        {
            label: "Football",
            type: Sport.FOOTBALL
        },
        {
            label: "Baseball",
            type: Sport.BASEBALL
        },
        {
            label: "Basketball",
            type: Sport.BASKETBALL
        }
    ];

    const tabs = sportMap.map((sport, index) => {
        return ({
            tabName: sport.label, tabContent:
                <GameSchedule sport={sport.type} onSelect={addGame}></GameSchedule>
        })
    });

    tabs.push({
        tabName: "Custom", tabContent:
            <CustomGameBuilder onSave={addGame}></CustomGameBuilder>
    })

    return (
        <div className="props-page-body-dark">
            <label className="games-header">Ticket Builder</label>
            <PbContainer label="Games">
                <div className="ticket-builder-games">
                    <div className="ticket-builder-games-body">
                        {games.length === 0 &&
                            <PbLabel value={"Add one or more games from schedule below to continue..."}></PbLabel>}
                        {games.length > 0 && gameItems}
                    </div>
                </div>
                <br/>
                <PbTabContainer tabs={tabs}></PbTabContainer>
            </PbContainer>
            <div className="pb-btn-row">
                <PbButton label="Abandon" btnClass="secondary" onClick={() => navigate(HOME)}></PbButton>
                <PbButton label="Back" onClick={() => setStep(1)}></PbButton>
                <PbButton label="Continue" isDisabled={games.length === 0} onClick={onSave}></PbButton>
            </div>
        </div>

    )
}

export default TicketBuilder;