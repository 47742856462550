import React from 'react'
import bannerImage from '../../public/sb_trophy.jpg';
import {LOGIN, REGISTER} from "../../shared/constants/RouteContants";
import {useNavigate} from "react-router-dom";

export const DashboardRegisterBanner = () => {
    const navigate = useNavigate();

    return (
        <div className="dashboard-register-banner">
            <div className="banner-content-container">
                <div className="dashboard-register-banner-header">
                    Hey sports fan!
                </div>
                <div className="dashboard-register-banner-content">
                    Pick your game, add your props and invite your fiends to get started!
                </div>
                <button onClick={() => navigate(REGISTER)} className="btn btn-danger dashboard-register-banner-button">
                    Register Now
                </button>
                <div className="banner-login">Already Registered?
                    <a onClick={() => navigate(LOGIN)}>&nbsp;&nbsp;Sign In</a>
                </div>
            </div>
            <div className="banner-image-container">
                <img className="banner-image" src={bannerImage} alt=""></img>
            </div>
        </div>
    );
}
