import React, {useEffect, useState} from 'react'
import {get, post, put} from "../../../shared/services/ApiService";

import {PageLoader} from "../../common/widgets/PageLoader";
import {useNavigate, useParams} from "react-router-dom";
import {GAME_LOBBY} from "../../../shared/constants/RouteContants";
import {buildModal, useModal} from "../../common/modals/Modal";
import {PropsEditor} from "../../prop/entry/PropsEditor";
import PbButton from "../../common/buttons/PbButton";
import PbTabContainer from "../../common/containers/PbTabContainer";
import Ticket from "../common/game-item/Ticket";

export const ResultsEditor = () => {
    const [ticket, setTicket] = useState(null);
    const [updatedProps, setUpdatedProps] = useState([]);
    const [loading, setLoading] = useState(true);

    const {setModal} = useModal();

    const navigate = useNavigate();
    const params = useParams();

    const loadData = () => {
        const ticketCode = params.ticketCode;
        get(`api/tickets/${ticketCode}`).then(ticket => {
            setTicket(ticket);
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, [])

    if (loading) {
        return (<PageLoader></PageLoader>);
    }

    const saveResults = () => {
        post(`api/tickets/${ticket.id}/results`, updatedProps).then(() => {
                setModal(buildModal("Results Submitted", "Results saved successfully.",
                    () => navigate(GAME_LOBBY + ticket.code)));
            },
            (error) => {
                console.error(error);
                setModal(buildModal("Submission Error", "Please try again. Results couldn't be saved."));
            })
    };

    const onPropUpdated = (updatedProp, value) => {
        const newProps = [...updatedProps];

        const existingProp = newProps.find(p => p.id === updatedProp.id);
        if (existingProp) {
            newProps.find(p => p.id === updatedProp.id).answer = value;
        } else {
            newProps.push({id: updatedProp.id, answer: value});
        }

        setUpdatedProps(newProps);

        ticket.games.flatMap(game => game.props)
            .find(p => p.id === updatedProp.id).answer = value;

        setTicket(ticket);
    }

    const gameTabs = ticket.games.map((game, index) => {
        return ({
            tabName: game.name, tabContent:
                <PropsEditor key={index} game={game} onPropUpdated={onPropUpdated}></PropsEditor>
        })
    });

    return (
        <div className="props-page-body-dark">
            <Ticket ticket={ticket}>
                <PbTabContainer tabs={gameTabs} showNavButtons={true}></PbTabContainer>
                <div className="pb-btn-row">
                    <PbButton label="Discard Changes" btnClass="secondary"
                              onClick={() => navigate(GAME_LOBBY + ticket.code)}></PbButton>
                    <PbButton label="Save Results" onClick={saveResults}></PbButton>
                </div>
            </Ticket>
        </div>
    )
}