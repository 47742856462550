import React, { Component } from 'react'
import PropTypes from "prop-types";
import {translate} from "../../../shared/services/LocalizationService";

export default class LabeledDropdown extends Component {

    onChange(event) {
        this.props.onChange(this.props.name, event.target.value)
    }

    render() {
        return (
            <div className="pb-field-group">
                <label>{this.props.label}</label>
                <select className="pb-field" value={this.props.value} onChange={this.onChange.bind(this)}>
                    {this.props.options.map((option, index) => {
                        return <option key={index} label={translate(option)}>{option}</option>;
                    })}
                </select>
            </div>
        );
    }
}

LabeledDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
};