import React, {useState} from 'react'
import PropTypes from "prop-types";
import PbRadio from "../../common/buttons/PbRadio";
import ResultsTable, {getPropsByView, RESULTS_VIEW_ALL, RESULTS_VIEW_RESOLVED, RESULTS_VIEW_UNRESOLVED} from "./table/ResultsTable";

export const ResultsView = ({ticket}) => {
    const [filterState, setFilterState] = useState(RESULTS_VIEW_ALL);

    const filterOptions = [
        {label: "All", value: RESULTS_VIEW_ALL, count: getPropsByView(ticket, RESULTS_VIEW_ALL).length},
        {label: "Resolved", value: RESULTS_VIEW_RESOLVED, count: getPropsByView(ticket, RESULTS_VIEW_RESOLVED).length},
        {label: "Unresolved", value: RESULTS_VIEW_UNRESOLVED, count: getPropsByView(ticket, RESULTS_VIEW_UNRESOLVED).length}
    ];

    return (
        <div>
            <div className="results-table-filter">
                <PbRadio options={filterOptions} currentValue={filterState}
                         onSelect={(value) => setFilterState(value)}></PbRadio>
            </div>
            <ResultsTable ticket={ticket} viewMode={filterState}></ResultsTable>
        </div>
    );
}

ResultsView.propTypes = {
    ticket: PropTypes.object.isRequired
};

export default ResultsView;