import PropTypes from "prop-types";
import React from "react";

export const HelpIcon = ({onClick}) => {
    return (
        <a onClick={onClick} className="help-icon">
            <i className="far fa-question-circle"></i>
        </a>
    );
}

HelpIcon.propTypes = {
    onClick: PropTypes.func.isRequired
};