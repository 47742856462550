const PbListPageControl = ({totalPages, currentPage, onPageChanged}) => {
    return (
        <div className="pb-list-page-control-container">
            <div className="pb-list-page-control">
                <a className={`${currentPage <= 1 ? "page-control-disabled" : ""}`}
                   onClick={() => onPageChanged(currentPage - 1)}>
                    <i className="fas fa-chevron-left"></i>
                </a>
                &nbsp;&nbsp;Page {currentPage} / {totalPages}&nbsp;&nbsp;
                <a className={`${currentPage >= totalPages ? "page-control-disabled" : ""}`}
                   onClick={() => onPageChanged(currentPage + 1)}>
                    <i className="fas fa-chevron-right"></i>
                </a>
            </div>
        </div>

    )
}

export default PbListPageControl;