import React, {useEffect} from "react";
import {AppHeader} from "./components/header/AppHeader";
import {AppFooter} from "./components/footer/AppFooter";
import {routes} from "./routes/Router";
import {downloadConfiguration, getServerVersion, isWsDebugMode} from "./shared/services/ConfigService";
import {ModalProvider} from "./components/common/modals/Modal";
import {HashRouter} from "react-router-dom";
import {getToken, isAuthenticated} from "./shared/services/AuthService";
import {StompSessionProvider} from "react-stomp-hooks";

export const App = () => {
    useEffect(() => {
        downloadConfiguration();
    }, []);

    const serverVersion = getServerVersion();

    const wsUrl = '/propsbuddy/messaging';
    const wsHeaders = {Authorization: `${getToken()}`};

    const onDebugMsg = isWsDebugMode() ? (msg) => console.log('STOMP: ' + msg) : () => {};

    return (
        <div className="props-window">
            <HashRouter>
                <ModalProvider>
                    <StompSessionProvider
                        brokerURL={wsUrl}
                        connectHeaders={wsHeaders}
                        enabled={isAuthenticated()}
                        debug={onDebugMsg}
                        reconnectDelay={20000}>
                        <AppHeader></AppHeader>
                        <div className="props-body">
                            <div className="container page-view">
                                {routes}
                            </div>
                            <AppFooter appVersion={serverVersion}></AppFooter>
                        </div>
                    </StompSessionProvider>
                </ModalProvider>
            </HashRouter>
        </div>
    )
};