import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {post} from '../../../shared/services/ApiService';
import DateService from '../../../shared/services/DateService';
import {PageLoader} from "../../common/widgets/PageLoader";
import {GameItem} from "../common/game-item/GameItem";

const today = new Date();

const GameSchedule = ({sport, onSelect}) => {
    const [date, setDate] = useState(today);
    const [games, setGames] = useState([]);
    const [gameCount, setGameCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        updateSchedule(date);
    }, [date, sport]);

    const updateSchedule = (date) => {
        if (!sport) {
            setGames([]);
            setGameCount(0);
            setLoading(false);
            return;
        }

        date.setHours(0, 0, 0, 0);
        const request = {
            date: date.toISOString(),
            sport: sport
        };
        setLoading(true);

        post('api/schedule/get', request).then(schedule => {
            setGames(schedule.games || []);
            setGameCount(schedule.totalGames || 0);
            setLoading(false);
        });
    };

    const addDay = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        setDate(newDate);
    };

    const subtractDay = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        setDate(newDate);
    };

    const gameRows = games.map((externalGame, index) => {

        const game = {};

        game.startTime = new Date(externalGame.gameDate);
        const home = externalGame.home;
        const away = externalGame.away;
        game.homeTeam = home.nickName || home.fullName;
        game.awayTeam = away.nickName || away.fullName;
        // game.name = `${game.awayTeam} vs ${game.homeTeam}`
        game.externalGameId = externalGame.id;
        game.externalGame = externalGame;
        game.sport = sport;
        game.totalPoints = externalGame.bettingLine ? externalGame.bettingLine.totalPoints : null;
        game.homeSpread = externalGame.bettingLine ? externalGame.bettingLine.spread : null;
        game.addDefaultProps = true;

        return (
            <GameItem key={index} game={game} onClick={() => onSelect(game)}></GameItem>
        );
    });

    const currentDate = DateService.parseDate(date, "MMM D, y");
    const allowSubtractDay = date > today;

    const datePicker = (
        <div className="schedule-date-select">
            {allowSubtractDay &&
                <i onClick={subtractDay} className="fas fa-minus-square"></i>
            }
            {currentDate}
            <i onClick={addDay} className="fas fa-plus-square"></i>
        </div>
    );

    return (
        <div>
            {datePicker}
            {loading &&
                <PageLoader></PageLoader>
            }
            {!loading &&
                <div className="schedule-body">
                    {gameCount > 0 &&
                        gameRows
                    }
                    {gameCount === 0 &&
                        <div className="schedule-no-games">
                            <div>
                                {!sport ? "Select sport to see schedule" : "No games available for date"}
                            </div>
                        </div>

                    }
                </div>
            }
        </div>
    );
}

GameSchedule.propTypes = {
    sport: PropTypes.string,
    onSelect: PropTypes.func.isRequired
}

export default GameSchedule;