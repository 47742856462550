import moment from 'moment';

export default class DateService {

    static parseDate(date, format) {
        format = format || "MMM D, y h:mm A";
        return moment(date).format(format);
    }

    // Used for formatting for datetime input
    static formatDatetimeLocal(date) {
        return moment(date).format('YYYY-MM-DD HH:mm');
    }
}