import React, {useState} from 'react'
import {PaymentManagerRow} from "./PaymentManagerRow";
import PbList from "../../common/list/PbList";

const PaymentManager = ({ticket}) => {
    const [entries, setEntries] = useState(ticket.entries);

    const onEntryUpdated = (updatedEntry) => {
        const currentEntries = [...entries];
        const index = currentEntries.findIndex((e) => e.id === updatedEntry.id)
        currentEntries[index] = updatedEntry;
        setEntries(currentEntries);
    }

    const getSortedRows = (filteredEntries) => {
        return filteredEntries.sort((a, b) => {
            if (a.firstName < b.firstName) {
                return -1;
            }
            return 1;
        }).map((entry, index) =>
            <PaymentManagerRow key={index} entry={entry} onUpdated={onEntryUpdated}></PaymentManagerRow>
        );
    }

    if (entries.length === 0) {
        return (
            <div className="payment-manager">
                No entries
            </div>
        );
    }

    const unpaidEntryRows = getSortedRows(entries.filter(entry => !entry.paid));
    const paidEntryRows = getSortedRows(entries.filter(entry => entry.paid));


    return (
        <div className="payment-manager">
            <div>Keep track of users who have paid (if applicable) by clicking the check mark beside the name
                of the user. The bell can be used to send a reminder to a user if they are late providing payment.
            </div>
            <label className="games-header">Unpaid Users</label>
            <PbList columns={3} pageSize={30} rows={unpaidEntryRows}></PbList>
            <label className="games-header">Paid Users</label>
            <PbList columns={3} pageSize={30} rows={paidEntryRows}></PbList>
        </div>
    )
}

export default PaymentManager;