import React from 'react';
import PropTypes from 'prop-types';
import DateService from "../../../shared/services/DateService";

const LabeledDateField = ({name, label, value, minValue, onChange}) => {
    if (!value) {
        value = new Date();
    } else if (typeof value.getMonth !== 'function') {
        value = new Date(value);
    }

    value = DateService.formatDatetimeLocal(value);

    if (minValue) {
        minValue = DateService.formatDatetimeLocal(minValue);
    }

    return (
        <div className="pb-field-group">
            <label>{label}</label>
            <input className="pb-field"
                   type="datetime-local"
                   min={minValue}
                   value={value}
                   onChange={(e) => onChange(name, new Date(e.target.value))}
            ></input>
        </div>
    );
}

LabeledDateField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
};

export default LabeledDateField;