import {useState} from "react";
import PropTypes from "prop-types";
import PbListPageControl from "./PbListPageControl";

const PbList = ({columns = 1, pageSize = 0, rows}) => {
    const [page, setPage] = useState(1);

    let className = "pb-list-content";
    if (columns === 2) {
        className += " pb-list-columns-2";
    }

    if (columns === 3) {
        className += " pb-list-columns-3";
    }

    let visibleRows = rows;
    let pages = 1;
    const totalRows = rows.length;

    if (pageSize > 0 && totalRows > pageSize) {
        pages = Math.ceil(totalRows / pageSize);

        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        visibleRows = rows.slice(startIndex, endIndex)
    }

    return (
        <div className="pb-list">
            {pages > 1 && <PbListPageControl currentPage={page}
                                             totalPages={pages}
                                             onPageChanged={(newPage) => setPage(newPage)}/>
            }
            <div className={className}>
                {visibleRows}
            </div>
        </div>
    )
}

PbList.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.number,
    pageSize: PropTypes.number
}

export default PbList;